// src/components/CTA.js
import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {ctaBg} from "../assets";
import Grow from '@mui/material/Grow';
import { Link } from 'react-router-dom';

function CTA() {
  return (
    <div style={{background: `linear-gradient(20deg, rgba(0,0,0,.8) 20%, rgba(0, 0, 0,.9) 80%), url(${ctaBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed"}}>
    <Container maxWidth="md">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'row' }} 
        py={4}
        gap="4rem"
      >
      
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
        <Box mt={3}>
          <Typography variant="h4" gutterBottom>
          Have fun with our collection of social games!
          </Typography>
          <Typography variant="body1" paragraph>
          Our site provides a secure and welcoming space for gamers from all backgrounds to dive into a broad selection of games. No matter if you're an experienced player or new to the gaming scene, our platform caters to every type of gamer. Our diverse array of games ranges from beloved classics to the latest in video slots, each featuring stunning visuals and engaging gameplay designed to captivate and entertain you.   
          </Typography>  
               <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/about"
            
          >
            About us
          </Button>
        </Box>
          </Grow>
      </Box>
    </Container>
    </div>
  );
}

export default CTA;
