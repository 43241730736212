import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  pallete: {
    primary: {
      dark: '#211522',
      main: '#130b12',
      main600: '#271624',
      main500: '#3a2035',
      main400: '#4e2b47',
      main300: '#613659',
      main200: '#815e7a',
      main100: '#a0869b',
      main50: '#c0afbd',
    },
    rgba: {
      main: 'rgba(19, 11, 18, 0.8)',
      main600: 'rgba(39, 22, 36, 0.8)',
      main500: 'rgba(58, 32, 53, 0.8)',
      main400: 'rgba(78, 43, 71, 0.8)',
      main300: 'rgba(97, 54, 89, 0.8)',
      main200: 'rgba(129, 94, 122, 0.8)',
      main100: 'rgba(160, 134, 155, 0.8)',
      main50: 'rgba(192, 175, 189, 0.8)',
    },
    text: {
      primary: '#ffffff',
    },
    secondary: {
      main: '#C197D2', 
      secondary: '#D3B1C2'
    },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Kode Mono, sans-serif', 
    color: "white",
    body1: {
      fontSize: "18px",
    },
    h3: {
      fontSize: '1.7rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.4rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
