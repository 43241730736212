import React from 'react';
import Container from "@mui/material/Container";
import Header from './Header';
import Typography from '@mui/material/Typography';

export default function About() {
  return (
    <div className="about-page">
    <Header/>
    <Container>
    <Typography variant="h3" textAlign="center" gutterBottom>
    Discover goldabold.com
    </Typography>

    <Typography variant="body1" paragraph>
  Enter the realm of goldabold, where thrilling gaming adventures await players at every level. Regardless of whether you're a gaming virtuoso or someone who enjoys a casual play, we have something special just for you.
</Typography>

<Typography variant="body1" paragraph>
  goldabold takes great pride in offering a broad spectrum of slot games free of charge, catering to diverse tastes. From classic fruit slots to the latest video slots, each game is crafted with precision by leading experts in the industry, ensuring stunning graphics and fluid gameplay.
</Typography>

<Typography variant="body1" paragraph>
  Our defining trait at goldabold is our dedication to providing all our games for free. You'll encounter no hidden costs or unexpected charges – only pure entertainment. Whether you're looking to pass the time, delve into new gaming adventures, or relax after a busy day, you can do so without any financial worry.
</Typography>

<Typography variant="body1" paragraph>
  In addition to our vast selection of slot games, goldabold offers a treasure trove of resources to enrich your gaming experience. Filled with tips, strategies, and insights, our platform is designed to boost your gaming skills and maximize your enjoyment.
</Typography>

<Typography variant="body1" paragraph>
  Don't delay any further! Venture into the world of goldabold now and start your exciting journey with some of the best free slot games around! With constant updates and endless possibilities for fun, an extraordinary experience is guaranteed.
</Typography>

    </Container>
      </div>
  )
}
