import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {ctaBg} from "../assets";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      goldabold {new Date().getFullYear()}{'.'} <br/>
      The Services are virtual social entertainment games, and do not involve any real money bets or prizes. 18+.
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',
        }} style={{
        }}
      >
        <Container maxWidth="sm">
        <Typography variant="body2">info@goldabold.com</Typography>
          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
        </Container>
      </Box>
  );
}
